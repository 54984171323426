<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>
									Emby is a platform that works similarly to PLEX.<br /><br />You need to have a SERVER Media installed, and then you need App , for the Client
									side.<br /><br /><strong>Server side support</strong> – Linux/Windows/NAS/MAC/DOCKER/FREEBSD.<br /><strong>App (client) side support</strong>
									–<br />AndroidTV<strong>/</strong>AmazonfireTV<strong>/</strong>NVShield<strong>/</strong>AppleTV<strong>/</strong>ChromeCast<strong>/</strong>Roku<strong>/</strong>Windows<strong>/</strong>HTML5<strong>/</strong>LG<strong>/</strong>SAMSUNG<strong>/</strong>PS4<strong>/</strong>XBOX1-360<strong>/</strong>WinMediaCenter<strong>/</strong>Kodi<strong>/</strong>PS3.<br />You
									might need Emby WiKi – <a href="https://github.com/MediaBrowser/Wiki">https://github.com/MediaBrowser/Wiki</a>
								</p>
								<p>
									<strong
										>This guide will take you with the steps necessary to get {{ $store.state.brandInfo.displayName }} service to run on your Windows Emby
										Server (so you could see it in your apps).</strong
									><br /><br />
								</p>
								<p>
									Enter the Server Config page via your browser (default –
									<a href="http://localhost:8096/web/" rel="noreferrer">http://localhost:8096/web/</a>)<br /><br />
								</p>
								<p>Go to Live TV section, and click on TV Sources + as shown in the picture.</p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.01.png`"
										><img
											src="../../../assets/images/1yNslwJ2mDkJGMqYkFgNfoqOJ-YYBtaNXQ.png?1564042917"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/1yNslwJ2mDkJGMqYkFgNfoqOJ-YYBtaNXQ.png?1564042917"
											data-fileid="48002263433"
											data-uniquekey="1564042896968"
											style="width: 100%"
									/></a>
								</p>
								<p><br /></p>
								<p>Then set the Type: to “M3U Tuner”, and enter under File or url:</p>
								<p>Insert the required m3u and replace <strong>YOUR_</strong><strong>EMAIL/ PASSWORD</strong> with your details<br /><br /></p>
								<p>
									<strong>Channels list and recent VOD</strong><br /><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
										>{{ $store.state.brandInfo.tvLink }}/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										><strong><span style="color: rgb(0, 0, 0)">/</span></strong
										>m3u8/livetv</a
									>
								</p>
								<p>
									<br /><strong>Movies&nbsp;</strong><br /><a
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										rel="noreferrer noopener"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
									>/m3u8/movies<br /><br /><strong>TV Shows</strong><br /><a
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"
										rel="noreferrer noopener"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
									>/m3u8/tvshows
								</p>
								<p>
									<br />*<strong
										>Make sure to change YOUR_EMAIL and PASSWORD fields in the link to your {{ $store.state.brandInfo.displayName }} email and password</strong
									><br /><strong>(Picture has old URL, please ignore)</strong>
								</p>
								<p><br /></p>
								<p><strong>TV Guide/EPG URL</strong><br />Select Lite or Full url.&nbsp;</p>
								<p><br /></p>
								<p>Regular version – 7 day listing&nbsp;</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`" rel="noreferrer noopener"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/full.xml.gz</a
									>
								</p>
								<p><br /></p>
								<p>Lite version - 48h listing (For old/small machines)</p>
								<p>
									UTC 0: <a :href="$store.state.brandInfo.epgLink" rel="noreferrer noopener" target="_top"></a
									><a :href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`" rel="noreferrer noopener"
										>{{ $store.state.brandInfo.tvLink }}/api/epg/<strong><span style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
										>/<strong><span style="color: rgb(0, 0, 0)">PASSWORD</span></strong
										>/lite.xml.gz</a
									>
								</p>
								<p><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.02.png`"
										><img
											src="../../../assets/images/dofscX0RD8lu48AdXn_L7FvkjnHJ5CUdBQ.png?1564042917"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/dofscX0RD8lu48AdXn_L7FvkjnHJ5CUdBQ.png?1564042917"
											data-fileid="48002263434"
											data-uniquekey="1564042896968"
											style="width: 100%"
									/></a>
								</p>
								<p><br /></p>
								<p>
									Now to setup the EPG data, Once you done setting up the streams from previous stages,<br />In the same Live TV section click on the “+” next to
									TV Guide Data Providers.
								</p>
								<p><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.03.png`"
										><img
											src="../../../assets/images/0GdmEZqLa_38Bh5Gp_6QrMqUUYTS_Cu_8g.png?1564042918"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/0GdmEZqLa_38Bh5Gp_6QrMqUUYTS_Cu_8g.png?1564042918"
											data-fileid="48002263435"
											data-uniquekey="1564042896968"
											style="width: 100%"
									/></a>
								</p>
								<p><br /></p>
								<p>Set the Country you’re at and Guide Source will be “XmlTV”<br />*The country you choose should be for time zone purposes only.</p>
								<p><br />Our system support these Countries: US, Canada, UK, France, Germany, Israel, Italy, Russia, UTC 0</p>
								<p><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.04.png`"
										><img
											src="../../../assets/images/tBa2u_oy6uJyIm8qG7VOB1GpQ8ljoQ64lQ.png?1564042918"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/tBa2u_oy6uJyIm8qG7VOB1GpQ8ljoQ64lQ.png?1564042918"
											data-fileid="48002263436"
											data-uniquekey="1564042896968"
											style="width: 100%"
									/></a>
								</p>
								<p>&nbsp;</p>
								<p>When you choose the country, make sure to use right url accordingly.<br /><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.05.png`"
										><img
											src="../../../assets/images/q3XDsaCDuX8Cho6UyLvcWWWk7Pee2IWWVg.png?1564042918"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/q3XDsaCDuX8Cho6UyLvcWWWk7Pee2IWWVg.png?1564042918"
											data-fileid="48002263438"
											data-uniquekey="1564042896968"
											style="width: 100%"
									/></a>
								</p>
								<p><br /></p>
								<p>Once you finished setting up the EPG, you’ll need to Click on “Refresh Guide Data” to be able and receive the updated data.<br /><br /><br /></p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.06.png`"
										><img
											src="../../../assets/images/s0MmX3byfWKUWUD7ioeKphWbLnPMd61HmQ.png?1564042918"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/s0MmX3byfWKUWUD7ioeKphWbLnPMd61HmQ.png?1564042918"
											data-fileid="48002263439"
											data-uniquekey="1564042896968"
											width="688"
											height="408"
									/></a>
								</p>
								<p>&nbsp;</p>
								<p>This is a view on the Browser using Chrome of Live-TV Guide. (Client/App)</p>
								<p>&nbsp;</p>
								<p>
									<a :href="`${$store.state.brandInfo.siteLink}/wp-content/uploads/2019/01/a.07.png`"
										><img
											src="../../../assets/images/A-7D2ggd55dPwwGAFhcX1JOCKJWoGVzYrg.png?1564042918"
											alt=""
											class="fr-fic fr-dii"
											data-filelink="../../../assets/images/A-7D2ggd55dPwwGAFhcX1JOCKJWoGVzYrg.png?1564042918"
											data-fileid="48002263440"
											data-uniquekey="1564042896968"
											width="684"
											height="342"
									/></a>
								</p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'howDoISetUpWithEmby',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
